import { Checkbox, FormControlLabel, IconButton, LinearProgress } from '@mui/material'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Box, Typography, TextField, Button, Stack, Divider, useMediaQuery, FormHelperText } from '@mui/material';
import { React, useEffect, useState } from 'react';
import { TimeStampToMonthDateYear, TimeStampToMonthDateYearWithTime } from '../../../../../helpers/time-formater';
import DownlaodCreditReport from './download-credit-report';
import { Auth } from '../../../../../auth/auth';
import { useRecoilState } from 'recoil';
import { kstheme } from '../../../../../theme/theme';
import { profileState } from '../../../../../recoil/profile-atom';
import CustomTooltip from '../../../../../helpers/custom-tooltip';
import { KSCloudUploadIcon, KSCloudDownloadIcon, KSDocumentSearchIcon, MyCustomIcon } from "../../../../../icons/custome-icon";
import { formState } from '../../../../../recoil/profile-atom';
import {
    getApiCallDynamic,
    postApiCallDynamic,
    uploadDocCustom,
    uploadFile,
} from "../../../../../services/api-service";
import { API } from "../../../../../services/api-config";


export default function LoanCreditReportStatus({
    creditData,
    handleCreditSubmit,
    handlePrevious,
    partnerInfoPath,
    creditPull,
    onReissueSubmit,
    creditReferenceNumber,
}) {
    let [profileData, setProfileData] = useRecoilState(profileState);
    const [reissueCreditReferenceNumber, setReissueCreditReferenceNumber] = useState(creditReferenceNumber);
    const [reissueClicked, setReissueClicked] = useState(false);
    const [pullClicked, setPullClicked] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const isMobile = useMediaQuery(kstheme.breakpoints.down('sm'));

// coded by santha 
    let [formValue, setFormValue] = useRecoilState(formState)
    const [hasCredCoAccount, setHasCredCoAccount] = useState(null);
    const [hasOtherAccount, setHasOtherAccount] = useState(null);
    const [spinner, setSpinner] = useState(false);
    const [finalValidationReset, setFinalValidationReset] = useState(false);
    const [file, setFile] = useState(null); // State for file upload
    const [uploadedFileName, setUploadedFileName] = useState(null); // State for file preview
    const [errorStatus, setErrorStatus] = useState(false);
    const [key, setKey] = useState(0); // Add this state to force input refresh



    // the function to get the values from the backend for the questions broker and borrower
    const fetchSavedValue = () => {
        setSpinner(true);
        getApiCallDynamic({ path: API.getCredcoAccount })
            .then((res) => {
                if (res?.status === 200) {
                    // Set values fetched from API
                    setHasCredCoAccount(res.data.isCredcoAccount);
                    setHasOtherAccount(res.data.isNonCredcoAccount);
                    setSpinner(false);
                    setFinalValidationReset(false);
                }
            })
            .catch((error) => {
                console.error('Error fetching CredCo account status:', error);
                setSpinner(false);
            });
    };


    useEffect(() => {
        if (Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP'])) {
            fetchSavedValue()
        }

    }, []);


    let generateText;
    if (creditData?.isPulled) {
        if (!creditData?.creditReportStatus) {
            generateText = `The credit report was pulled on ${TimeStampToMonthDateYearWithTime(creditData?.pullDate)} with qualifying credit score of ${creditData?.creditScore}.`;
        } else {
            generateText = `Credit consent was given on ${TimeStampToMonthDateYearWithTime(creditData?.consentDate)}! ${profileData?.role !== 'Borrower' ? 'Credit report pull is unsuccessful, please contact Credco at (800) 637-2422. ' : 'Credit pull failed, please report to your loan officer. '} You still can continue the application and submit.`;
        }
    } else if (!creditData?.isPulled && !creditData.isUserUploadedDocument) {
        generateText = profileData?.role === 'Broker' ? `The credit consent was given on ${TimeStampToMonthDateYear(creditData?.consentDate)}!` : `Broker has not given the permission to pull the credit report.`;
    }

    const handleInputChange = (event) => {
        setReissueCreditReferenceNumber(event.target.value);
    };

    const handleReissueSubmit = () => {
        if (!reissueCreditReferenceNumber.trim()) {
            setErrorMessage('Credit reference number is required');
        } else {
            setErrorMessage('');
            onReissueSubmit({ creditReferenceNumber: reissueCreditReferenceNumber });
            setReissueClicked(true);
        }
    };

    useEffect(() => {
        if (Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP']) && reissueClicked) {
            setPullClicked(false);
        }
        //if (creditData?.creditReportStatus && reissueClicked)


    }, [reissueClicked]);

    useEffect(() => {
        if (Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP']) && pullClicked) {
            setReissueClicked(false);
        }
    }, [pullClicked]);



    // handleChange: Handle file input change, and update the `file` state with the selected file.
    const handleChange = (event) => {
        setFile(event.target.files[0]); // Update state with the first file selected
        setKey(prevKey => prevKey + 1);
    };

    // Define formData: Create an object with `formId`, `file`, and `documentType` to submit with the API request.
    const formData = {
        formId: formValue.id, // Form ID to associate with the document
        file: file, // The file selected in the input
        documentType: "creditReports" // Document type to specify the kind of file being uploaded
    };

    // useEffect: If `file` is set, update the uploaded file name and initiate file upload.
    useEffect(() => {
        if (file) {
            setUploadedFileName(file.name); // Set the uploaded file name for display
            uploadDocCustom(formData); // Upload the document to the API
        }
    }, [file]); // Dependency on file state

    // handleUploadFileChange: Create a file URL and open the uploaded file in a new window/tab.
    const handleUploadFileChange = (e) => {
        if (file) {
            const fileURL = URL.createObjectURL(file); // Create URL for the uploaded file
            window.open(fileURL); // Open the file in a new window/tab
        }
    };

    // uploadDocCustom: Upload the file and formId to the server via API.
    const uploadDocCustom = async (formData) => {
        console.log("Uploading...");

        try {
            // Create FormData object for file upload
            const formDataToSend = new FormData();
            formDataToSend.append('formId', formData.formId); // Add form ID
            formDataToSend.append('file', formData.file); // Add the file object
            formDataToSend.append('documentType', formData.documentType); // Add document type

            // Make the API call to upload the document
            const response = await postApiCallDynamic({
                data: formDataToSend, // Send FormData
                refresh: (res) => res, // Return raw response
                path: API.postCreditDocument, // API endpoint for uploading the document
                header: 'POST', // HTTP method
            });

            // Handle Conflict Error: If status is 409, log the error details.
            if (response.status === 409) {
                const errorData = await response.json(); // Parse the error response
                console.error('Conflict Error:', errorData); // Log conflict error details
            }

            // Validate Response: Check if the response structure is valid and handle error codes.
            if (!response || !response.status || !response.data) {
                throw new Error('Invalid response structure');
            }

            if (response.data.errorCode) {
                const { errorCode, errorMessage } = response.data; // Extract error code and message
                console.log('Error Code:', errorCode); // Log error code
                console.log('Error Message:', errorMessage); // Log error message

                if (errorCode === 9095) {
                    setErrorStatus(true); // Set error status if errorCode 9095
                    console.error('Error:', errorMessage || 'Invalid form ID or data');
                } else if (errorCode === 9098) {
                    console.error('Error:', errorMessage || 'Uploaded document details not matched with borrower details');
                }
            } else {
                // If status is successful (2xx), log success
                console.log('Form ID and file sent successfully:', response);
            }
        } catch (error) {
            console.error('Error sending form ID and file:', error.message); // Handle any other errors
        }
    };


    console.log("error status", errorStatus);

    // useEffect for Error Status: Log the error status whenever it is updated.
    useEffect(() => {
        console.log('Error status updated:', errorStatus); // Log the updated error status
    }, [errorStatus]); // Dependency on errorStatus state

    // handleFileDownload: Create a download link and trigger the download of the uploaded file.
    const handleFileDownload = () => {
        if (file) {
            const fileURL = URL.createObjectURL(file); // Create URL for the uploaded file
            const link = document.createElement('a'); // Create an anchor tag
            link.href = fileURL; // Set the href to the file URL
            link.download = file.name || 'downloaded-file'; // Use the file's name or a default name
            document.body.appendChild(link); // Append the link to the body
            link.click(); // Trigger the download
            document.body.removeChild(link); // Remove the link from the DOM
            URL.revokeObjectURL(fileURL); // Clean up the file URL after download
        }
    };


    return (
        <>
            <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>

                <>
                    <Box>
                        <Typography variant='h3' mb={2} mt={2}>Credit Report</Typography>
                        <Typography variant='body1' mb={2} color={'#222222BF'}>
                            {generateText}
                        </Typography>
                    </Box>

                    <Box ml={2} mb={4}>
                        {creditData?.creditReferenceNumber && (profileData?.role !== 'Borrower' || (creditData?.creditReportStatus && creditData?.isPulled)) &&
                            <Typography variant='body1' mb={2} color={'#222222BF'}>
                                Credit Reference #: {creditData.creditReferenceNumber}
                            </Typography>
                        }

                        {Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP']) && (
                            <>
                                {
                                    hasCredCoAccount ? (
                                        <>

                                            <Box xs={10} mr={2}>
                                                {(!creditData?.creditReportStatus && creditData?.isPulled && creditData?.creditReferenceNumber) &&
                                                    <DownlaodCreditReport creditReportId={creditData.id} />
                                                }
                                            </Box>
                                            {creditData?.creditReferenceNumber && (profileData?.role !== 'Borrower' || (creditData?.creditReportStatus && creditData?.isPulled)) &&
                                                <Divider />}

                                            <Box ml={2} mt={4} mb={4}>
                                                {Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP']) && (
                                                    <Typography variant='h3' mb={2} mt={2}>Re-issue Credit Report</Typography>
                                                )}

                                                {Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP']) && (
                                                    <>
                                                        <Box xs={12}>
                                                            <Typography variant="body1">Enter Credit Reference Number:</Typography>
                                                            <Box mb={4} mt={1}>
                                                                <TextField
                                                                    id="creditReferenceNumber"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    sx={{ width: isMobile ? '70%' : '40%' }}
                                                                    value={reissueCreditReferenceNumber}
                                                                    onChange={handleInputChange}
                                                                    placeholder='Enter credit reference number'
                                                                    required
                                                                />
                                                                <Box mb={4} mt={1} ml={0.5}>
                                                                    {errorMessage && (
                                                                        <FormHelperText sx={{ color: '#D94166', fontSize: '1rem' }}>
                                                                            {errorMessage}
                                                                        </FormHelperText>
                                                                    )}
                                                                </Box>
                                                            </Box>
                                                            <Box mb={3}>
                                                                <CustomTooltip title='Please be aware that re-issue of a credit report is free.'>
                                                                    <Button
                                                                        onClick={handleReissueSubmit}
                                                                        variant="contained"
                                                                        sx={{ fontWeight: 500, fontSize: '16px' }}
                                                                    >
                                                                        {creditData?.isPulled ? 'Reissue Credit Report' : 'Reissue Credit Report'}
                                                                    </Button>
                                                                </CustomTooltip>
                                                            </Box>
                                                            <Box ml={2} mb={4} mr={2}>
                                                                {Auth.hasAnyRole(['MLO', 'Broker', 'MLO Owner', 'MLP']) && reissueClicked &&
                                                                    <Box mr={2}>
                                                                        <Typography variant='body1' mb={2} color={'#D94166'}>
                                                                            {creditData?.errorMessage}
                                                                        </Typography>
                                                                    </Box>
                                                                }
                                                            </Box>
                                                        </Box>
                                                    </>
                                                )}
                                            </Box>

                                        </>) : (<>
                                            <Box xs={10} mr={2}>
                                                <DownlaodCreditReport


                                                    creditReportId={creditData.id}
                                                    hasCredCoAccount={hasCredCoAccount} />
                                            </Box>
                                            <Button
                                                component="label"
                                                size="small"
                                                variant='contained'
                                                startIcon={<KSCloudUploadIcon
                                                    color={"white"} />}
                                                disabled={!hasOtherAccount}



                                            >
                                                {/* File input handling */}
                                                <input
                                                    hidden type='file'
                                                    onChange={handleChange}
                                                />
                                                {(creditData.isUserUploadedDocument || creditData.isPulled) ? ("Re-Upload") : ("Upload")}

                                            </Button>
                                        </>

                                    )}
                            </>

                        )}
                    </Box>


                    <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Button onClick={handlePrevious} startIcon={<ArrowBackIos />}>
                                Previous
                            </Button>
                            <Button onClick={handleCreditSubmit} endIcon={<ArrowForwardIos />}>
                                Save and Next
                            </Button>
                        </Stack>
                    </Box>
                </>
            </Stack>
        </>
    );
}